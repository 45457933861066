'use client';

import type { ReactNode } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
const client = new QueryClient();
const ReactQueryProvider = ({
  children
}: Readonly<{
  children: ReactNode;
}>) => {
  return <QueryClientProvider client={client} data-sentry-element="QueryClientProvider" data-sentry-component="ReactQueryProvider" data-sentry-source-file="react-query-provider.tsx">
			{children}
		</QueryClientProvider>;
};
export default ReactQueryProvider;