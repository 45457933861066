/* eslint-disable @typescript-eslint/no-non-null-assertion */

export const publicConfig = {
	app: {
		host: process.env.NEXT_PUBLIC_APP_HOST!,
		url: new URL(process.env.NEXT_PUBLIC_APP_HOST!),
		gitCommitSha: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA! || 'development',
		developer: process.env.NEXT_PUBLIC_YOUR_NAME!,
		chromeExtensionId: process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID!,
	},
	tailwind: {
		isIndicatorEnabled: process.env.NEXT_PUBLIC_ENABLE_TAILWIND_INDICATOR! === 'true',
	},
	posthog: {
		key: process.env.NEXT_PUBLIC_POSTHOG_KEY!,
		environment: process.env.NEXT_PUBLIC_POSTHOG_ENVIRONMENT!,
	},
	anon: {
		appConfig: {
			environment: process.env.NEXT_PUBLIC_ANON_ENV! as 'local'
				| 'development'
				| 'staging'
				| 'sandbox'
				| 'prod',
			clientId: process.env.NEXT_PUBLIC_ANON_SDKCLIENT_ID!,
			app: 'linkedin',
			company: 'Dunbar',
			companyLogo: 'http://localhost:3000/apple-touch-icon.png',
			chromeExtensionId: process.env.NEXT_PUBLIC_ANON_CHROME_EXTENSION_ID!,
		},
		debug: process.env.DISABLE_ANON_DEBUG !== 'true',
	},
	pusher: {
		key: process.env.NEXT_PUBLIC_PUSHER_KEY!,
		cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER!,
	},
	knock: {
		publicKey: process.env.NEXT_PUBLIC_KNOCK_PUBLIC_KEY!,
	},
}

export const serverConfig = {
	app: {
		isDev: process.env.NODE_ENV === 'development',
		enableSlackNotifications: process.env.ENABLE_SLACK_NOTIFICATIONS! === 'true',
	},
	database: {
		url: process.env.DATABASE_URL!,
		readReplicas: (process.env.DATABASE_READ_REPLICAS || '').split(',').filter(Boolean),
		readReplicaUrl1: process.env.DATABASE_READ_REPLICA_URL1!,
		type: process.env.DATABASE_TYPE!,
	},
	redis: {
		url: process.env.REDIS_URL!,
	},
	anon: {
		apiKey: process.env.ANON_API_KEY!,
		prodApiKey: process.env.ANON_PROD_API_KEY!,
		sdkClientId: process.env.ANON_SDKCLIENT_ID!,
		env: (process.env.ANON_ENV ?? 'sandbox') as 'sandbox' | 'prod',
	},
	clerk: {
		secretKey: process.env.CLERK_SECRET_KEY!,
		appId: process.env.CLERK_APP_ID!,
		instanceId: process.env.CLERK_INSTANCE_ID!,
	},
	resend: {
		apiKey: process.env.RESEND_API_KEY!,
	},
	knock: {
		apiKey: process.env.KNOCK_API_KEY!,
		signingKey: process.env.KNOCK_SIGNING_KEY!,
	},
	datastax: {
		apiEndpoint: process.env.DATABASE_API_ENDPOINT!,
		apiToken: process.env.DATABASE_API_TOKEN!,
	},
	proApis: {
		apiKey: process.env.PRO_APIS_API_KEY!,
	},
	slack: {
		botToken: process.env.SLACK_BOT_TOKEN!,
	},
	dev: {
		productionReadReplicaDatabaseUrl: process.env.PRODUCTION_DATABASE_READ_REPLICA_URL,
	},
}

/* eslint-enable @typescript-eslint/no-non-null-assertion */
