'use client';

import { useUser } from '@clerk/nextjs';
import { usePathname, useSearchParams } from 'next/navigation';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { publicConfig } from '~/config';
const PostHogPageView = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();

  // Track pageviews
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (pathname && posthog) {
      let url = globalThis.origin + pathname;
      if (searchParams.toString()) {
        url = `${url}?${searchParams.toString()}`;
      }
      posthog.capture('$pageview', {
        $current_url: url
      });
    }
  }, [pathname, searchParams, posthog]);
  const {
    user
  } = useUser();

  // Track User
  useEffect(() => {
    if (user) {
      posthog.identify(user.id, {
        name: user.fullName,
        email: user.primaryEmailAddress?.emailAddress,
        environment: publicConfig.posthog.environment || 'development'
      });
    }
  }, [posthog, user]);
  return null;
};
export default PostHogPageView;